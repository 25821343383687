.shopping-list__wrapper {

}

.shopping-list__items {
  padding-top: 10px;
}

.shopping-list__item {
  align-items: center;
  display: flex;
}

.shopping-list__item input {
  width: 190px;
  font-size: 22px;
  height: 35px;
}

.shopping-list__item-text {
  font-size: 22px;
  width: 70%;
}

.shopping-list__item-text-checked {
  text-decoration: line-through;
  color: lightgrey;
}

.shopping-list__item-text-display {
  cursor: pointer;
}

.shopping-list__new-item {
  align-items: center;
  display: flex;
  border-bottom: 1px dashed grey;
  padding-bottom: 10px;
  padding-top: 10px;
}

.shopping-list__new-item input {
  font-size: 22px;
  height: 35px;
}

.shopping-list__name {
  align-items: center;
  display: flex;
}

.shopping-list__name input {
  font-family: "Amatic SC";
  font-size: 40px;
  font-weight: bold;
  width: 240px;
}
