:root {
    /* Colors */
    --root-background-color: #fff;
    --content-background-color: #eef0e5;
    --header-color: #cde7ca;
    --header-link-active: #b2d4b6;
    --navigation-link-color: #fff;
    --red-warning-color: #ef8383;
    --red-warning-color-hover: #e84a4a;
    --orange-pending-color: #ff8c00;
    --yellow-share-color: #ffffaa;
    --yellow-share-color-hover: #d9d980;

    --text-header-color: #756a64;
    --button-highlight-color: #90b493;
    --button-highlight-color-hover: #698c6c;

    /* Fonts */
    --font-family-headers: "Amatic SC";
    --font-family-logo: "Sofia";
    --font-family-body: "Encode Sans Condensed";

    /* Sizes */
    --navigation-link-font-size: 19px;
    --header-input-size: 17px;
    --input-h1-size: 18px;
    --input-h2-size: 17px;
    --input-tiny-size: 8px;

    /* Other */
    --common-border-radius: 10px;
    --common-dashed-line: 1px var(--text-header-color) dashed;
    --common-solid-line: 1px var(--text-header-color) solid;
}

/*
---------------------- BODY ----------------------
*/
body {
    background-color: var(--root-background-color);
    margin: 0;
}

div,
input,
label,
p,
pre,
table,
textarea,
select {
    font-family: var(--font-family-body);
}

button {
    font-family: var(--font-family-headers);
    font-size: 20px;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: var(--font-family-headers);
}

h1 {
    font-size: 40px;
    margin: 5px 0;
}

p {
    line-height: 30px;
}

.backgroundLayer {
    background-image: url("media/lemon.png");
    background-repeat: no-repeat;
    background-size: 500px;
    background-position: 65% 0;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 0;
    margin: auto;
}

.centerWrap {
    width: 100%;
    margin: auto;
}

.logowrap {
    max-width: 1080px;
    margin: auto;
    z-index: 10;
}

.headerLine {
    background-color: var(--header-color);
    height: 72px;
    position: absolute;
    top: 60px;
    width: 100%;
    z-index: 4;
}

.accountIconStyle {
    width: 65px;
    text-align: center;
    color: var(--text-header-color);
}

/*
---------------------- MODAL ----------------------
*/

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}

.ModalContent {
    position: relative;
    border: 1px solid #ccc;
    background: var(--content-background-color);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    width: 500px;
    max-height: 550px;
}

.FriendListModalContent {
    width: 800px;
}

@media screen and (max-width: 840px) {
    .FriendListModalContent {
        width: 360px;
        left: 0;
        right: 0;
        padding: 0;
    }
}

@media screen and (max-width: 580px) {
    .ModalContent {
        width: 360px;
        left: 0;
        right: 0;
        padding: 0;
    }
}

.ModalOverlay {
    position: fixed;
    outline: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 50;
    display: flex;
    align-items: center;
    justify-content: center;
}

/*
---------------------- HEADER ----------------------
*/
.header {
    position: relative;
    max-width: 1080px;
    margin: auto;
    font-family: var(--font-family-headers);
    min-width: 320px;
    z-index: 999;
}

.header .active {
    background-color: var(--header-link-active);
}

.header li {
    display: inline;
    list-style-type: none;
    margin: 0;
}

.header ul {
    background-color: var(--header-color);
    padding: 0;
    margin: 0;
}

.header li a {
    color: var(--navigation-link-color);
    display: inline-block;
    font-size: var(--navigation-link-font-size);
    font-weight: bold;
    padding: 24px 7px;
    text-decoration: none;
}

.mainHeader {
    color: #756a64;
    font-family: var(--font-family-logo);
    font-size: 40px;
    margin: 0;
}

/*
---------------------- CONTENT ----------------------
*/
.content {
    background-color: var(--content-background-color);
    border-radius: var(--common-border-radius);
    min-width: 320px;
    position: relative;
    top: 80px;
    max-width: 1080px;
    margin: auto;
    width: 90%;
    z-index: 10;
    min-height: 500px;
    padding: 10px;
}

.content h2 {
    font-family: var(--font-family-headers);
    margin: 0;
}

/*
---------------------- ACCOUNT ----------------------
*/
.account-friendlist table {
    font-size: 18px;
}

.account-friendlist tr {
    text-align: left;
}

.account-friendlist-name {
    width: 250px;
}

.account-friendlist-action {
    width: 62px;
}

.account-friendlist h3 {
    padding-left: 5px;
    text-align: left;
    font-size: 20px;
    margin-bottom: 5px;
}

/*
---------------------- CHANGE RECIPE ----------------------
*/
.change-recipe-wrapper {
    bottom: 0;
    display: table;
    left: 0;
    position: relative;
    width: 100%;
}

@media screen and (max-width: 530px) {
    /* SMALL SCREEN */
    .change-recipe-wrapper-left {
        float: left;
        min-width: 100%;
    }

    .change-recipe-wrapper-right {
        float: left;
        width: 300px;
    }

    .change-recipe-instructions textarea {
        margin-left: -15px;
    }

    .change-recipe-functionkeys button {
        font-size: 13px;
    }

    .change-recipe-instructions {
        width: 260px;
    }

    .ingredientGroupNameInput {
        font-size: var(--input-h2-size);
        width: 150px;
    }

    .styledSelectSheet {
        font-size: var(--input-tiny-size);
        width: 65px;
    }

    .ingredientAmountInput {
        font-size: var(--input-tiny-size);
        width: 30px;
    }

    .ingredientNameInput {
        font-size: var(--input-tiny-size);
        width: 70px;
    }

    /* END SMALL SCREEN */
}

.change-recipe-wrapper-left {
    display: table-column;
    float: left;
}

.change-recipe-wrapper-right {
    display: table-column;
    float: left;
    padding-left: 10px;
}

.change-recipe-topwrapper {
    border-bottom: var(--common-dashed-line);
    display: flex;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.change-recipe-infobox {
    display: flex;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.change-recipe-ingredientlist .groupHeader {
    padding-top: 10px;
    padding-bottom: 5px;
}

.change-recipe-name-input {
    font-size: var(--input-h1-size);
    width: 100%;
}

.change-recipe-name {
    padding-right: 10px;
}

.change-recipe-instructions {
    border-bottom: var(--common-dashed-line);
    height: 400px;
    padding: 10px 10px 20px;
}

.change-recipe-instructions textarea {
    border-radius: var(--common-border-radius);
    height: 100%;
    padding: 5px;
    resize: none;
    line-height: 1.5em;
    white-space: pre-wrap;
    width: 100%;
}

.change-recipe-categories {
    overflow: hidden;
    padding: 10px;
}

.ingredientGroupNameInput {
    font-size: var(--input-h2-size);
    width: 100%;
}

.ingredientAmountInput {
    max-width: 50px;
}

.ingredientNameInput {
    max-width: 150px;
}

.inputOK {
    border: 1px solid white;
}

.inputError {
    border: 1px dashed var(--red-warning-color);
}

/*
---------------------- LOGIN PAGE ----------------------
*/
.login-page {
    text-align: center;
}

.login-page h1 {
    font-size: 40px;
}

.login-page p {
    font-family: var(--font-family-headers);
    font-size: 25px;
}

/*
---------------------- SEARCH RECIPE ----------------------
*/
.SearchRecipe table {
    cursor: pointer;
    font-family: var(--font-family-headers);
    font-size: 33px;
}

.SearchRecipe-SearchField {
    border-bottom: var(--common-dashed-line);
    padding-bottom: 10px;
}

/*
---------------------- SHOW RECIPE ----------------------
*/

@media screen and (max-width: 600px) {
    .show-recipe-ingredients {
        float: left;
        width: 280px;
    }

    .show-recipe-instructions {
        float: left;
        width: 280px;
    }
}

.show-recipe {
    display: table;
}

.show-recipe h2 {
    color: var(--text-header-color);
    font-size: 25px;
}

.show-recipe h1 {
    font-size: 40px;
    margin: 0;
}

.show-recipe-top p {
    color: var(--text-header-color);
    font-size: 14px;
}

.show-recipe-instructions {
    white-space: pre-wrap;
    overflow: hidden;
    padding: 10px;
}

.show-recipe-ingredients {
    padding: 10px;
    float: left;
}

.show-recipe-ingredients th {
    border-bottom: var(--common-dashed-line);
    text-align: left;
    padding: 5px;
}

.show-recipe-ingredients td {
    padding: 5px;
}

/*
---------------------- FRIEND PANEL ----------------------
 */
.friend-panel {
    border: 1px solid grey;
    padding: 10px;
    border-radius: 10px;
    height: 100px;
    width: 365px;
    background: var(--header-color);
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.friend-panel-thumbnail {
    border-radius: 50%;
    border: 1px solid grey;
    width: 96px;
}

.friend-panel-button {
    border: solid 2px black;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0);
    cursor: pointer;
    margin-right: 5px;
    font-family: var(--font-family-body);
    font-size: 15px;
    height: 35px;
}

.friend-panel-button:hover {
    background: rgba(255, 255, 255, 0.7);
}

@media screen and (max-width: 580px) {
    .friend-panel-thumbnail {
        width: 65px;
    }

    .friend-panel {
        width: 320px;
    }
}

/*
---------------------- COMMON ----------------------
*/
input {
    border: none;
    border-radius: var(--common-border-radius);
    padding-left: 5px;
}

select {
    border: none;
    cursor: pointer;
    height: 30px;
    background: #fff;
}

.iconButton {
    background-color: var(--content-background-color);
    border: none;
    cursor: pointer;
    height: 30px;
    width: 30px;
}

.arrowButton {
    background-color: var(--content-background-color);
    border: none;
    cursor: pointer;
    height: 30px;
    width: 30px;
    font-size: 13px;
}

.styledButton {
    background-color: var(--content-background-color);
    border-radius: var(--common-border-radius);
    border: 2px solid transparent;
    cursor: pointer;
}

.iconButton {
    height: 44px;
    width: 44px;
    text-align: center;
    color: black;
}

.styledButton:hover {
    border: 2px solid grey;
}

.buttonHighlighted {
    background: var(--button-highlight-color);
}

.buttonOk {
    color: green;
}

.buttonPending {
    color: var(--orange-pending-color);
}

.buttonError {
    color: var(--red-warning-color);
}
