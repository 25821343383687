.shopping-list-menu-top {
    border-bottom: var(--common-dashed-line);
    padding-bottom: 5px;
}

.shopping-list-menu {
    width:100%;
    height:100%;
    min-height: 500px;
}

.shopping-list-menu h2 {
    font-size: 22px;
    font-weight: normal;
}

.MenuTile {
    border-radius: var(--common-border-radius);
    cursor: pointer;
    height: 120px;
    margin: 5px;
    padding: 5px;
    word-break: keep-all;
    width: 120px;
    text-align: center;
}

.TransparentTile {
    align-items: center;
    margin: -5px;
    padding: -5px;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.7);
    display:flex;
}

.ExistingTile {
    background: var(--button-highlight-color);
}

.ExistingTile:hover {
    background: var(--button-highlight-color-hover);
}

.DeleteTile {
    background: var(--red-warning-color);
}

.DeleteTile:hover {
    background: var(--red-warning-color-hover);
}

.ShareTile {
    background: var(--yellow-share-color);
}

.ShareTile:hover {
    background: var(--yellow-share-color-hover);
}

.AddNewTile {
    background: lightgrey;
}

.AddNewTile:hover {
    background: #95d2cc;
}
